<template>
  <b-form-group
    class="font-weight-semibold mb-0"
    label="Status">
    <v-select
      v-model="innerValue"
      v-bind="$attrs"
      :options="options"
      :reduce="val => val['value']"
      label="label"
      placeholder="Status"
      clearable
    />
  </b-form-group>
</template>

<script>
export default {
  props: {
    statusFilter: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
      ]
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
        this.$emit('change-status-filter')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
