export default (query) => {
  if (query) {
    let str = ''
    for (const [key, value] of Object.entries(query)) {
      str += `&${key}=${value}`
    }
    return str
  }
  return ''
}
