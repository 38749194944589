<template>
  <div>
    <b-row class="align-items-end mb-1">
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchCodeOrNameValue"
          label="Code or Name"
          placeholder="Search code or name"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <SearchTextInput
          v-model="searchTypeGroupValue"
          label="Type Group or Main Group"
          placeholder="Search group or main group"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <StatusDropdown
          :status-filter.sync="statusFilterValue"
          @change-status-filter="fetchFilter()" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="justify-end"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'

export default {
  components: {
    SearchTextInput,
    StatusDropdown
  },
  props: {
    search: {
      type: Object,
      default: () => (
        {
          codeOrName: '',
          typeGroup: '',
          typeMainGroup: ''
        }
      )
    },
    searchCodeOrName: {
      type: String,
      default: ''
    },
    searchTypeGroup: {
      type: String,
      default: ''
    },
    statusFilter: {
      type: String,
      default: null
    }
  },
  computed: {
    searchCodeOrNameValue: {
      get () {
        return this.searchCodeOrName
      },
      set (val) {
        this.$emit('update:search-code-or-name', val)
      }
    },
    searchTypeGroupValue: {
      get () {
        return this.searchTypeGroup
      },
      set (val) {
        this.$emit('update:search-type-group', val)
      }
    },
    searchTypeMainGroup: {
      get () {
        return this.search.typeMainGroup
      },
      set (val) {
        this.$emit('update:search-type-main-group', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
