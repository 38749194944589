<template>
  <b-row class="">
    <b-col>
      <div>
        <b-form-group label="Status">
          <b-form-checkbox
            v-model="selected"
            class="custom-control-success d-inline-block"
            name="check-button"
            switch />
          <h3 class="d-inline-block ml-1">
            <b-badge
              :variant="selected ? 'light-success' : 'light-warning'">
              {{ status | formatStatusData }}
            </b-badge>
          </h3>
        </b-form-group>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    selected: {
      get () {
        return this.status === 'active'
      },
      set (val) {
        const status = val ? 'active' : 'inactive'
        this.$emit('update:status', status)
      }
    }
  }
}
</script>

<style>

</style>
